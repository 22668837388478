
import LoadingComponent from '@/components/LoadingComponent.vue';
import { NutritionalPlan, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { Patient } from '@/models/Patient';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import MacrosDistributionCard from './MacrosDistributionCard.vue';
import MealsCard from './MealsCard.vue';
import NutritionalPatientInfo from './NutritionalPatientInfo.vue';
import NutritionalPlanDeleteModal from './NutritionalPlanDeleteModal.vue';
import NutritionalPlanHeader from './NutritionalPlanHeader.vue';

export default defineComponent({
  components: {
    LoadingComponent,
    NutritionalPlanDeleteModal,
    MealsCard,
    MacrosDistributionCard,
    NutritionalPatientInfo,
    NutritionalPlanHeader,
  },
  emits: ['nutritional-plan-deleted'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const storeProfile = useProfileStore();

    const tabsMenu = ref([
      { label: t(`nutrition.tabs.patientData`), to: 'patient-data' },
      { label: t(`nutrition.tabs.foodDistribution`), to: 'food-distribution' },
      { label: t(`nutrition.tabs.menuTemplate`), to: 'menu-template' },
    ]);

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const nutritionalPlanId = route.params.nutritionalPlan as string;
    const nutritionalPlanFormDto: Ref<NutritionalPlanFormDto | undefined> = ref();
    const loadingNutritionalPlan = ref(true);
    const nutritionalPlanDeleteModalRef = ref();
    const title = computed(() => nutritionalPlanFormDto.value?.name as string);
    const breadcrumb = ref([] as Array<any>);
    const userCanEdit = computed(() => {
      if (isAdminUser.value || isReceptionistUser.value) {
        return true;
      }

      if (!storeProfile.userId || !nutritionalPlanFormDto.value?.professional.id || !isSpecialistUser.value) {
        return false;
      }

      return nutritionalPlanFormDto.value?.professional.id === storeProfile.userId;
    });

    const nutritionalPlan = ref();
    const fetchNutritionalPlan = async () => {
      const result = await nutritionalPlanService.findById(props.patient.id, nutritionalPlanId);
      loadingNutritionalPlan.value = false;
      if (!(result instanceof ServiceError)) {
        breadcrumb.value = [
          { label: t('breadcrumb.plans'), to: `/patient/${props.patient.id}/nutrition/plans` },
          {
            label: result.name,
            to: '#',
            disabled: true,
          },
        ];
        nutritionalPlan.value = result;
        nutritionalPlanFormDto.value = new NutritionalPlanFormDto(result);
      }
    };

    const onNutritionalPlanChanged = (nutritionalPlanChanged: NutritionalPlan) => {
      nutritionalPlan.value = nutritionalPlanChanged;
      nutritionalPlanFormDto.value = new NutritionalPlanFormDto(nutritionalPlanChanged);
    };

    const onDeleteNutritionalPlan = async () => {
      nutritionalPlanDeleteModalRef.value?.openDialog();
    };

    const onNutritionalPlanDeleted = async () => {
      router.push(`/patient/${props.patient.id}/nutrition/plans`);
    };

    const onNutritionalPlanEnabledChanged = async (enabled: boolean) => {
      if (nutritionalPlanFormDto.value) {
        nutritionalPlanFormDto.value.enabled = enabled;
        const result = await nutritionalPlanService.updateEnabled(
          props.patient.id,
          nutritionalPlanFormDto.value.id,
          enabled,
        );
        if (!(result instanceof ServiceError)) {
          onNutritionalPlanChanged(result);
          toast.add({
            severity: 'success',
            summary: `${t('messages.notifications.successEditNutritionPlan')}`,
            life: 3000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditNutritionalPlan')}`,
            life: 3000,
          });
        }
      }
    };

    onMounted(async () => {
      await fetchNutritionalPlan();
    });
    return {
      route,
      title,
      breadcrumb,
      tabsMenu,
      nutritionalPlanFormDto,
      userCanEdit,
      nutritionalPlanDeleteModalRef,
      loadingNutritionalPlan,

      onDeleteNutritionalPlan,
      onNutritionalPlanChanged,
      onNutritionalPlanDeleted,
      onNutritionalPlanEnabledChanged,
      nutritionalPlan,
    };
  },
});
