import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 1,
  class: "meals-no-edit-container"
}
const _hoisted_4 = { class: "meal-no-edit-name" }
const _hoisted_5 = { class: "meal-no-edit-share" }
const _hoisted_6 = { class: "meal-no-edit-total-kcal" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "meals-edit-container" }
const _hoisted_9 = { class: "p-grid" }
const _hoisted_10 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_11 = { for: "name" }
const _hoisted_12 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_13 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_14 = { for: "share" }
const _hoisted_15 = { class: "meal-edit-total" }
const _hoisted_16 = {
  key: 3,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_NutritionalPlanConfirmModal = _resolveComponent("NutritionalPlanConfirmModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeadingComponent, {
        title: _ctx.$t('nutrition.nutritionalPlan.mealsDistributionTitle'),
        divider: false,
        "sub-heading": true
      }, {
        right: _withCtx(() => [
          _renderSlot(_ctx.$slots, "headingActions", {}, () => [
            _withDirectives((_openBlock(), _createBlock(_component_Button, {
              disabled: !_ctx.userCanEditNutritionalPlan,
              class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon-edit",
                  "aria-label": _ctx.$t('common.edit'),
                  "aria-hidden": "true"
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["disabled", "class"])), [
              [
                _directive_tooltip,
                _ctx.$t('common.edit'),
                void 0,
                { bottom: true }
              ]
            ])
          ], true)
        ]),
        _: 3
      }, 8, ["title"]),
      (_ctx.nutritionalPlan.meals)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass([{ 'p-invalid': !_ctx.nutritionalPlanCanBeUpdated }, "info-porcentages"])
                }, _toDisplayString(_ctx.$t('nutrition.error-porcentages-invalid')), 3))
              : _createCommentVNode("", true),
            (!_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nutritionalPlanFormDto.meals, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "p-field p-col-6 p-fluid"
                    }, [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(item.name ?? _ctx.$t(`nutrition.${item.mealType}`)), 1),
                      _createElementVNode("p", _hoisted_5, _toDisplayString(item.share) + " %", 1),
                      _createElementVNode("p", _hoisted_6, _toDisplayString(((_ctx.nutritionalPlan.currentCalories * item.share) / 100).toFixed(2)) + " " + _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.nutritionalPlanFormDto && _ctx.editMode)
              ? (_openBlock(), _createElementBlock("form", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nutritionalPlanFormDto.meals, (meal, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "meal-item-edit p-col-6 p-fluid"
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('nutrition.nutritionalPlan.name')), 1),
                            _createVNode(_component_HealzInput, {
                              id: "title",
                              modelValue: meal.name,
                              "onUpdate:modelValue": ($event: any) => ((meal.name) = $event),
                              placeholder: meal.name ?? _ctx.$t(`nutrition.${meal.mealType}`),
                              editable: _ctx.editMode,
                              type: "text"
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "editable"])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _cache[1] || (_cache[1] = _createElementVNode("label", { for: "time" }, "Categoría*", -1)),
                            _createVNode(_component_Dropdown, {
                              modelValue: meal.mealType,
                              "onUpdate:modelValue": ($event: any) => ((meal.mealType) = $event),
                              options: _ctx.mealsOptionsDropdown,
                              "option-label": "label",
                              "option-value": "value",
                              class: _normalizeClass({
                    'no-editable': !_ctx.editMode,
                  })
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "class"])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('nutrition.nutritionalPlan.share')), 1),
                            _createVNode(_component_InputNumber, {
                              id: "share",
                              modelValue: meal.share,
                              "onUpdate:modelValue": ($event: any) => ((meal.share) = $event),
                              class: _normalizeClass({ 'no-editable': !_ctx.editMode }),
                              "max-fraction-digits": 2,
                              min: 0,
                              max: 100
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "class"])
                          ])
                        ]),
                        _createElementVNode("p", _hoisted_15, [
                          _createTextVNode(_toDisplayString(_ctx.$t('nutrition.nutritionalPlan.totalCalories')) + " ", 1),
                          _createElementVNode("span", null, _toDisplayString(((_ctx.nutritionalPlan.currentCalories * meal.share) / 100).toFixed(2)), 1)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary p-button-medium",
                    label: _ctx.$t('common.cancel'),
                    onClick: _ctx.onCancelEdition
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    class: "p-button p-button-info p-button-medium",
                    disabled: !_ctx.nutritionalPlanCanBeUpdated,
                    loading: _ctx.waitSubmit,
                    label: _ctx.$t('common.save'),
                    onClick: _ctx.onSubmit
                  }, null, 8, ["disabled", "loading", "label", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            heading: _ctx.$t('nutrition.nutritionalPlan.emptyEnergyTitle'),
            text: _ctx.$t('nutrition.nutritionalPlan.emptyEnergyMessage'),
            secondary: false,
            icon: "icon-list_check"
          }, null, 8, ["heading", "text"]))
    ]),
    _createVNode(_component_NutritionalPlanConfirmModal, {
      ref: "updateNutritionPlanMealsCardModalRef",
      "nutritional-plan-id": _ctx.nutritionalPlan.id,
      onUpdatedNutritionalPlan: _ctx.updatedNutritionalPlan
    }, null, 8, ["nutritional-plan-id", "onUpdatedNutritionalPlan"])
  ], 64))
}