import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_NutritionalPlanHeader = _resolveComponent("NutritionalPlanHeader")!
  const _component_NutritionalPatientInfo = _resolveComponent("NutritionalPatientInfo")!
  const _component_MealsCard = _resolveComponent("MealsCard")!
  const _component_MacrosDistributionCard = _resolveComponent("MacrosDistributionCard")!
  const _component_NutritionalPlanDeleteModal = _resolveComponent("NutritionalPlanDeleteModal")!

  return (_ctx.loadingNutritionalPlan)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_ctx.nutritionalPlanFormDto)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_NutritionalPlanHeader, {
            "loading-nutritional-plan": _ctx.loadingNutritionalPlan,
            title: _ctx.title,
            "tabs-menu": _ctx.tabsMenu,
            breadcrumb: _ctx.breadcrumb,
            "user-can-edit": _ctx.userCanEdit,
            "nutritional-plan-enabled": _ctx.nutritionalPlanFormDto.enabled,
            onNutritionalPlanDeleted: _ctx.onDeleteNutritionalPlan,
            onNutritionalPlanEnabledChanged: _ctx.onNutritionalPlanEnabledChanged
          }, null, 8, ["loading-nutritional-plan", "title", "tabs-menu", "breadcrumb", "user-can-edit", "nutritional-plan-enabled", "onNutritionalPlanDeleted", "onNutritionalPlanEnabledChanged"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_NutritionalPatientInfo, {
              "nutritional-plan": _ctx.nutritionalPlan,
              onNutritionalPlanChanged: _ctx.onNutritionalPlanChanged
            }, null, 8, ["nutritional-plan", "onNutritionalPlanChanged"]),
            _createVNode(_component_MealsCard, {
              "nutritional-plan": _ctx.nutritionalPlanFormDto,
              onNutritionalPlanChanged: _ctx.onNutritionalPlanChanged
            }, null, 8, ["nutritional-plan", "onNutritionalPlanChanged"]),
            _createVNode(_component_MacrosDistributionCard, {
              "nutritional-plan": _ctx.nutritionalPlanFormDto,
              onNutritionalPlanChanged: _ctx.onNutritionalPlanChanged
            }, null, 8, ["nutritional-plan", "onNutritionalPlanChanged"])
          ]),
          _createVNode(_component_NutritionalPlanDeleteModal, {
            ref: "nutritionalPlanDeleteModalRef",
            nutritionalPlanId: _ctx.nutritionalPlanFormDto.id,
            patientId: _ctx.patient.id,
            onNutritionalPlanDeleted: _ctx.onNutritionalPlanDeleted
          }, null, 8, ["nutritionalPlanId", "patientId", "onNutritionalPlanDeleted"])
        ], 64))
      : _createCommentVNode("", true)
}