
import EmptyState from '@/components/EmptyState.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import inputHelper from '@/helpers/Input.helper';
import { MealType, NutritionalPlan, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { useProfileStore } from '@/store/profile.module';
import 'chartjs-adapter-moment';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, PropType, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import HeadingComponent from '../../HeadingComponent.vue';
import HealzInput from '../../healz/ui/HealzInput.vue';
import NutritionalPlanConfirmModal from '../menus/NutritionalPlanConfirmModal.vue';

export default defineComponent({
  components: { HeadingComponent, HealzInput, EmptyState, NutritionalPlanConfirmModal },
  emits: ['nutritional-plan-changed'],
  props: {
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlanFormDto>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const toast = useToast();
    const storeProfile = useProfileStore();
    const { t } = useI18n();

    const updateNutritionPlanMealsCardModalRef = ref();

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const editMode = ref(false);
    const waitSubmit = ref(false);

    const nutritionalPlanFormDto = ref<NutritionalPlanFormDto>(inputHelper.createCopy(props.nutritionalPlan));

    watchEffect(() => {
      nutritionalPlanFormDto.value = inputHelper.createCopy(props.nutritionalPlan);
    });

    const userCanEditNutritionalPlan = computed(() => {
      return isAdminUser.value || isReceptionistUser.value || isSpecialistUser.value;
    });

    const nutritionalPlanCanBeUpdated = computed(() => {
      if (nutritionalPlanFormDto.value) {
        return (
          nutritionalPlanFormDto.value.meals.map((meal) => meal.share).reduce((sum, value) => sum + value, 0) == 100
        );
      }
      return false;
    });

    const onSubmit = async () => {
      if (nutritionalPlanFormDto.value) {
        updateNutritionPlanMealsCardModalRef.value?.openDialog(nutritionalPlanFormDto.value);
      }
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      setInitialVaules();
    };

    const setInitialVaules = () => {
      if (nutritionalPlanFormDto.value) {
        nutritionalPlanFormDto.value = inputHelper.createCopy(props.nutritionalPlan);
      }
    };

    const mealsOptionsDropdown = computed(() => {
      return Object.keys(MealType).map((mealType) => {
        return {
          label: t(`nutrition.${mealType}`),
          value: mealType,
        };
      });
    });

    const updatedNutritionalPlan = (nutritionalPlanUpdated: NutritionalPlan) => {
      editMode.value = false;
      nutritionalPlanFormDto.value = new NutritionalPlanFormDto(nutritionalPlanUpdated);

      toast.add({
        severity: 'success',
        summary: `${t('messages.notifications.successEditNutritionPlan')}`,
        life: 3000,
      });

      emit('nutritional-plan-changed', nutritionalPlanUpdated);
    };

    return {
      updateNutritionPlanMealsCardModalRef,
      dateFormat,
      nutritionalPlanFormDto,
      editMode,
      waitSubmit,
      userCanEditNutritionalPlan,
      nutritionalPlanCanBeUpdated,
      mealsOptionsDropdown,

      onSubmit,
      onCancelEdition,
      updatedNutritionalPlan,
    };
  },
});
