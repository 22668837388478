
import EmptyState from '@/components/EmptyState.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import inputHelper from '@/helpers/Input.helper';
import {
  calculateMealBites,
  calculateMealCalories,
  calculateMealGrPerKilo,
  calculateMealQuantity,
} from '@/helpers/NutritionHelper';
import { MacroType, NutritionalPlan, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { useProfileStore } from '@/store/profile.module';
import 'chartjs-adapter-moment';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, PropType, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import HeadingComponent from '../../HeadingComponent.vue';
import NutritionalPlanConfirmModal from '../menus/NutritionalPlanConfirmModal.vue';

export default defineComponent({
  components: { HeadingComponent, EmptyState, NutritionalPlanConfirmModal },
  emits: ['nutritional-plan-changed'],
  props: {
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlanFormDto>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const toast = useToast();
    const storeProfile = useProfileStore();
    const { t } = useI18n();

    const updateNutritionPlanMacrosDistributionCardModalRef = ref();

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const editMode = ref(false);
    const waitSubmit = ref(false);

    const nutritionalPlanFormDto = ref<NutritionalPlanFormDto>(inputHelper.createCopy(props.nutritionalPlan));

    watchEffect(() => {
      nutritionalPlanFormDto.value = inputHelper.createCopy(props.nutritionalPlan);
    });

    const userCanEditNutritionalPlan = computed(() => {
      return isAdminUser.value || isReceptionistUser.value || isSpecialistUser.value;
    });

    const nutritionalPlanCanBeUpdated = computed(() => {
      if (nutritionalPlanFormDto.value) {
        return (
          +nutritionalPlanFormDto.value.proteinShare +
            +nutritionalPlanFormDto.value.carbohydratesShare +
            +nutritionalPlanFormDto.value.fatShare ==
          100
        );
      }
      return false;
    });

    const onSubmit = async () => {
      if (nutritionalPlanFormDto.value) {
        updateNutritionPlanMacrosDistributionCardModalRef.value?.openDialog(nutritionalPlanFormDto.value);
      }
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      setInitialValues();
    };

    const setInitialValues = () => {
      if (nutritionalPlanFormDto.value) {
        nutritionalPlanFormDto.value = inputHelper.createCopy(props.nutritionalPlan);
      }
    };

    const proteinValues = computed(() => {
      return calculateMacronutrientValues(
        props.nutritionalPlan.currentCalories,
        nutritionalPlanFormDto.value.proteinShare,
        MacroType.Protein,
      );
    });

    const carbsValues = computed(() => {
      return calculateMacronutrientValues(
        props.nutritionalPlan.currentCalories,
        nutritionalPlanFormDto.value.carbohydratesShare,
        MacroType.Carbs,
      );
    });

    const fatValues = computed(() => {
      return calculateMacronutrientValues(
        props.nutritionalPlan.currentCalories,
        nutritionalPlanFormDto.value.fatShare,
        MacroType.Fat,
      );
    });

    function calculateMacronutrientValues(totalCalories: number, share: number, macroType: MacroType) {
      const calories = ref(calculateMealCalories(totalCalories, share));
      const quantity = ref(calculateMealQuantity(calories.value, macroType));
      const bites = ref(calculateMealBites(quantity.value));
      const grPerKilo = ref(calculateMealGrPerKilo(quantity.value, props.nutritionalPlan.weight));

      function recalculateValues(newValue: any) {
        if (!newValue.value) {
          return;
        }

        calories.value = calculateMealCalories(totalCalories, newValue.value);
        quantity.value = calculateMealQuantity(calories.value, macroType);
        bites.value = calculateMealBites(quantity.value);
        grPerKilo.value = calculateMealGrPerKilo(quantity.value, props.nutritionalPlan.weight);
      }

      return {
        calories,
        quantity,
        bites,
        grPerKilo,
        recalculateValues,
      };
    }

    const updatedNutritionalPlan = (nutritionalPlanUpdated: NutritionalPlan) => {
      editMode.value = false;
      nutritionalPlanFormDto.value = new NutritionalPlanFormDto(nutritionalPlanUpdated);

      toast.add({
        severity: 'success',
        summary: `${t('messages.notifications.successEditNutritionPlan')}`,
        life: 3000,
      });

      emit('nutritional-plan-changed', nutritionalPlanUpdated);
    };

    return {
      updateNutritionPlanMacrosDistributionCardModalRef,
      dateFormat,
      nutritionalPlanFormDto,
      editMode,
      waitSubmit,
      userCanEditNutritionalPlan,
      nutritionalPlanCanBeUpdated,

      onSubmit,
      onCancelEdition,
      updatedNutritionalPlan,

      proteinValues,
      carbsValues,
      fatValues,
    };
  },
});
