
import dateFormat from '@/helpers/DateFormat.helper';
import { getMifflinStJeorValue } from '@/helpers/NutritionHelper';
import { Genders } from '@/models/Genders';
import { DietType } from '@/models/MedicalTest';
import { NutritionalPlan, NutritionalPlanFormDto, updateNutritionalPlanData } from '@/models/NutritionalPlan';
import { Intolerances } from '@/models/PatientInfo';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import { isUpdateNutritionalPlanFormValid, updateNutritionalPlanForm } from '@/validation/nutritionalPlanForm';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, PropType, Ref, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import HeadingComponent from '../../HeadingComponent.vue';
import NutritionalPlanConfirmModal from '../menus/NutritionalPlanConfirmModal.vue';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { HeadingComponent, NutritionalPlanConfirmModal },
  emits: ['nutritional-plan-changed', 'wait-submit'],
  props: {
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlan>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const toast = useToast();
    const storeProfile = useProfileStore();
    const { t } = useI18n();
    const patientId = route.params.patientId as string;
    const updateNutritionPlanPatientDataModalRef = ref();

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);
    const nutritionalPlanFormDto: Ref<NutritionalPlanFormDto> = ref(new NutritionalPlanFormDto(props.nutritionalPlan));

    const editMode = ref(false);
    const waitSubmit = ref(false);

    const showValidation = ref(false);
    const validatedNutritionalPlanForm = computed(() => updateNutritionalPlanForm(nutritionalPlanFormDto.value));
    const valid = computed(() => isUpdateNutritionalPlanFormValid(validatedNutritionalPlanForm.value));

    watchEffect(() => {
      nutritionalPlanFormDto.value = new NutritionalPlanFormDto(props.nutritionalPlan);
    });

    const minRangeYearBirth = ref('1920');
    const maxRangeYearBirth = ref(new Date().getFullYear());

    const currentDesviation = computed(
      () => +(nutritionalPlanFormDto.value.currentCalories - totalCalories.value).toFixed(2),
    );

    const currentDesviationPercentage = computed(() => {
      const absoluteDifference = Math.abs(totalCalories.value - nutritionalPlanFormDto.value.currentCalories);
      const avg = (totalCalories.value + nutritionalPlanFormDto.value.currentCalories) / 2;

      if (avg == 0) {
        return 0;
      }
      if (currentDesviation.value < 0) {
        return -((absoluteDifference / avg) * 100).toFixed(2);
      }
      return ((absoluteDifference / avg) * 100).toFixed(2);
    });

    const genderOptions = ref([
      { label: t(`user.${Genders.Female}`), value: Genders.Female },
      { label: t(`user.${Genders.Male}`), value: Genders.Male },
    ]);

    const dietTypeOptions = ref([
      { label: t('dietType.keto'), value: DietType.Keto },
      { label: t('dietType.lowCarb'), value: DietType.LowCarb },
      { label: t('dietType.midCarb'), value: DietType.MidCarb },
      { label: t('dietType.highCarb'), value: DietType.HighCarb },
    ]);

    const activityFrequencyOptions = ref(
      Array.from({ length: 10 }, (_, index) => 1 + index * 0.1).map((v) => {
        return { label: v.toFixed(1), value: +v.toFixed(1) };
      }),
    );

    const numberOfMealsOptions = ref(
      Array.from({ length: 5 }, (_, index) => index + 1).map((v) => {
        return { label: v, value: v };
      }),
    );

    const imc = computed(() =>
      nutritionalPlanFormDto.value.weight && nutritionalPlanFormDto.value.height
        ? (nutritionalPlanFormDto.value.weight / Math.pow(nutritionalPlanFormDto.value.height / 100, 2)).toFixed(2)
        : '-',
    );

    const age = computed(() => dateFormat.calculateAge(nutritionalPlanFormDto.value.birthdate));

    const basalCalories = computed(
      () =>
        +getMifflinStJeorValue(
          age.value,
          nutritionalPlanFormDto.value.gender,
          nutritionalPlanFormDto.value.weight,
          nutritionalPlanFormDto.value.height,
        ).toFixed(2),
    );

    const totalCalories = computed(
      () => +(basalCalories.value * nutritionalPlanFormDto.value.activityFrequency).toFixed(2),
    );

    const foodIntoleranceOptions = ref(
      Object.keys(Intolerances).map((k) => ({
        value: k,
        label: t(`cgm-patient-info.foodIntolerances.${k}`),
      })),
    );

    const userCanEditNutritionalPlan = computed(() => {
      return isAdminUser.value || isReceptionistUser.value || isSpecialistUser.value;
    });

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      if (nutritionalPlanFormDto.value) {
        if (nutritionalPlanFormDto.value.meals) {
          updateNutritionPlanPatientDataModalRef.value?.openDialog(
            updateNutritionalPlanData(nutritionalPlanFormDto.value),
          );
        } else {
          await updateNutritionalPlanService(updateNutritionalPlanData(nutritionalPlanFormDto.value));
        }
      }
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      setInitialVaules();
    };

    const setInitialVaules = () => {
      if (nutritionalPlanFormDto.value) {
        nutritionalPlanFormDto.value = new NutritionalPlanFormDto(props.nutritionalPlan);
      }
    };

    const onIntolerancesChange = () => {
      if (
        nutritionalPlanFormDto.value.foodIntolerances.length > 0 &&
        nutritionalPlanFormDto.value.foodIntolerances[nutritionalPlanFormDto.value.foodIntolerances.length - 1] ==
          Intolerances.NO
      ) {
        nutritionalPlanFormDto.value.foodIntolerances = [Intolerances.NO];
      }

      if (nutritionalPlanFormDto.value.foodIntolerances.length > 1) {
        nutritionalPlanFormDto.value.foodIntolerances = nutritionalPlanFormDto.value.foodIntolerances.filter(
          (i) => i != Intolerances.NO,
        );
      }
    };

    const updatedNutritionalPlan = (nutritionalPlanUpdated: NutritionalPlan) => {
      editMode.value = false;
      nutritionalPlanFormDto.value = new NutritionalPlanFormDto(nutritionalPlanUpdated);

      toast.add({
        severity: 'success',
        summary: `${t('messages.notifications.successEditNutritionPlan')}`,
        life: 3000,
      });

      emit('nutritional-plan-changed', nutritionalPlanUpdated);
    };

    const updateNutritionalPlanService = async (nutritionalPlaFormDtoUpdated: NutritionalPlanFormDto) => {
      try {
        const nutritionalPlanUpdated = await nutritionalPlanService.update(
          patientId,
          nutritionalPlaFormDtoUpdated.id,
          nutritionalPlanFormDto.value,
          false,
        );
        if (!(nutritionalPlanUpdated instanceof ServiceError)) {
          updatedNutritionalPlan(nutritionalPlanUpdated);
        } else {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditNutritionalPlan')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        }
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditNutritionalPlan')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      }
    };

    return {
      updateNutritionPlanPatientDataModalRef,
      dateFormat,
      nutritionalPlanFormDto,
      validatedNutritionalPlanForm,
      valid,
      showValidation,
      editMode,
      waitSubmit,
      userCanEditNutritionalPlan,
      minRangeYearBirth,
      maxRangeYearBirth,
      genderOptions,
      dietTypeOptions,
      foodIntoleranceOptions,
      activityFrequencyOptions,
      numberOfMealsOptions,
      currentDesviation,
      currentDesviationPercentage,
      age,
      basalCalories,
      totalCalories,
      imc,

      onSubmit,
      onCancelEdition,
      onIntolerancesChange,
      updatedNutritionalPlan,
    };
  },
});
