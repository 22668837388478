import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "c-plan-distribution-container" }
const _hoisted_4 = { class: "c-plan-distribution-item" }
const _hoisted_5 = { class: "c-plan-distribution-item__title" }
const _hoisted_6 = { class: "c-plan-distribution-item__values" }
const _hoisted_7 = { class: "c-plan-distribution-item__value" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "c-plan-distribution-item__value" }
const _hoisted_10 = { class: "c-plan-distribution-item__value helzy-bites" }
const _hoisted_11 = { class: "c-plan-distribution-item__value" }
const _hoisted_12 = { class: "c-plan-distribution-item__value" }
const _hoisted_13 = { class: "c-plan-distribution-item" }
const _hoisted_14 = { class: "c-plan-distribution-item__title" }
const _hoisted_15 = { class: "c-plan-distribution-item__values" }
const _hoisted_16 = { class: "c-plan-distribution-item__value" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "c-plan-distribution-item__value" }
const _hoisted_19 = { class: "c-plan-distribution-item__value helzy-bites" }
const _hoisted_20 = { class: "c-plan-distribution-item__value" }
const _hoisted_21 = { class: "c-plan-distribution-item__value" }
const _hoisted_22 = { class: "c-plan-distribution-item" }
const _hoisted_23 = { class: "c-plan-distribution-item__title" }
const _hoisted_24 = { class: "c-plan-distribution-item__values" }
const _hoisted_25 = { class: "c-plan-distribution-item__value" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "c-plan-distribution-item__value" }
const _hoisted_28 = { class: "c-plan-distribution-item__value helzy-bites" }
const _hoisted_29 = { class: "c-plan-distribution-item__value" }
const _hoisted_30 = { class: "c-plan-distribution-item__value" }
const _hoisted_31 = {
  key: 1,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_NutritionalPlanConfirmModal = _resolveComponent("NutritionalPlanConfirmModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeadingComponent, {
        title: _ctx.$t('nutrition.nutritionalPlan.macrosDistributionTitle'),
        divider: false,
        "sub-heading": true
      }, {
        right: _withCtx(() => [
          _renderSlot(_ctx.$slots, "headingActions", {}, () => [
            _withDirectives((_openBlock(), _createBlock(_component_Button, {
              disabled: !_ctx.userCanEditNutritionalPlan,
              class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon-edit",
                  "aria-label": _ctx.$t('common.edit'),
                  "aria-hidden": "true"
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["disabled", "class"])), [
              [
                _directive_tooltip,
                _ctx.$t('common.edit'),
                void 0,
                { bottom: true }
              ]
            ])
          ], true)
        ]),
        _: 3
      }, 8, ["title"]),
      (_ctx.nutritionalPlan.meals)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass({ 'p-invalid': !_ctx.nutritionalPlanCanBeUpdated })
                }, _toDisplayString(_ctx.$t('nutrition.error-porcentages-invalid')), 3))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[7] || (_cache[7] = _createElementVNode("i", { class: "icon-proteins" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.PROTEIN`)), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.distribution')), 1),
                    (_ctx.editMode)
                      ? (_openBlock(), _createBlock(_component_InputNumber, {
                          key: 0,
                          id: "proteinShare",
                          modelValue: _ctx.nutritionalPlanFormDto.proteinShare,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nutritionalPlanFormDto.proteinShare) = $event)),
                          class: _normalizeClass({ 'no-editable': !_ctx.editMode }),
                          "max-fraction-digits": 2,
                          min: 0,
                          max: 100,
                          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.proteinValues.recalculateValues($event)))
                        }, null, 8, ["modelValue", "class"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.nutritionalPlanFormDto.proteinShare) + " %", 1))
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.proteinValues.calories), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.healzy-bites')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.proteinValues.bites), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.proteinValues.quantity), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr-kg-weight')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.proteinValues.grPerKilo), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[8] || (_cache[8] = _createElementVNode("i", { class: "icon-fats" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.FAT`)), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.distribution')), 1),
                    (_ctx.editMode)
                      ? (_openBlock(), _createBlock(_component_InputNumber, {
                          key: 0,
                          id: "fatShare",
                          modelValue: _ctx.nutritionalPlanFormDto.fatShare,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nutritionalPlanFormDto.fatShare) = $event)),
                          class: _normalizeClass({ 'no-editable': !_ctx.editMode }),
                          "max-fraction-digits": 2,
                          onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fatValues.recalculateValues($event)))
                        }, null, 8, ["modelValue", "class"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.nutritionalPlanFormDto.fatShare) + " %", 1))
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.fatValues.calories), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.healzy-bites')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.fatValues.bites), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.fatValues.quantity), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr-kg-weight')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.fatValues.grPerKilo), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _cache[9] || (_cache[9] = _createElementVNode("i", { class: "icon-hc" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(`nutrition.CARBS`)), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.distribution')), 1),
                    (_ctx.editMode)
                      ? (_openBlock(), _createBlock(_component_InputNumber, {
                          key: 0,
                          id: "carbsShare",
                          modelValue: _ctx.nutritionalPlanFormDto.carbohydratesShare,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.nutritionalPlanFormDto.carbohydratesShare) = $event)),
                          class: _normalizeClass({ 'no-editable': !_ctx.editMode }),
                          "max-fraction-digits": 2,
                          onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.carbsValues.recalculateValues($event)))
                        }, null, 8, ["modelValue", "class"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_26, _toDisplayString(_ctx.nutritionalPlanFormDto.carbohydratesShare) + " %", 1))
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.carbsValues.calories), 1)
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.healzy-bites')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.carbsValues.bites), 1)
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.carbsValues.quantity), 1)
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.gr-kg-weight')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.carbsValues.grPerKilo), 1)
                  ])
                ])
              ])
            ]),
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary p-button-medium",
                    label: _ctx.$t('common.cancel'),
                    onClick: _ctx.onCancelEdition
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    class: "p-button p-button-info p-button-medium",
                    disabled: !_ctx.nutritionalPlanCanBeUpdated,
                    loading: _ctx.waitSubmit,
                    label: _ctx.$t('common.save'),
                    onClick: _ctx.onSubmit
                  }, null, 8, ["disabled", "loading", "label", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            heading: _ctx.$t('nutrition.nutritionalPlan.emptyMacrosTitle'),
            text: _ctx.$t('nutrition.nutritionalPlan.emptyMacrosMessage'),
            secondary: false,
            icon: "icon-list_check"
          }, null, 8, ["heading", "text"]))
    ]),
    _createVNode(_component_NutritionalPlanConfirmModal, {
      ref: "updateNutritionPlanMacrosDistributionCardModalRef",
      "nutritional-plan-id": _ctx.nutritionalPlan.id,
      onUpdatedNutritionalPlan: _ctx.updatedNutritionalPlan
    }, null, 8, ["nutritional-plan-id", "onUpdatedNutritionalPlan"])
  ], 64))
}